import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({urlVideo}) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center ">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:grid grid-cols-2 flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[150px] lg:bottom-[50px] md:text-start text-center text-white">
                    <div className="w-full md:text-start text-center p-4">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[2].slogan}</h1>
                        <p className="">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                    </div>

                    <div className="md:w-[400px] md:h-[400px] w-[200px] h-[200px] mx-auto my-auto">
                    <img
                            src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2FCRIST_Mesa%20de%20trabajo%201.png?alt=media&token=461e3d7b-d11f-4ac6-9f55-9650835bc32b"}
                            alt='no found'
                            loading='lazy'
                            className='w-full h-full object-cover'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;